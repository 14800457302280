

@tailwind components;
@tailwind utilities;

@font-face {
  font-family: myFirstFont;
  src: 
   url("./styles//Fonts/calibrib.ttf") format("truetype");
   font-weight: 900;
 
  }


*{
  margin: 0;
  padding: 0;
  font-family: myFirstFont ;
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}
.animatedColor{
  transition: all .2s ease;
  cursor: pointer;
}

.invoiceCard{
  background: #1488CC;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2B32B2, #1488CC);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2B32B2, #1488CC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  
}


.white-box{
  z-index: 2;
  width: 50vw;
  height: 70%;
  /* background-color: rgb(236, 236, 236); */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;

}

@media (max-width: 600px){
 
.white-box{
  z-index: 2;
  width: 80vw;
  height: 70%;
  /* background-color: rgb(236, 236, 236); */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;

}
}




 ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(110, 110, 110, 0.3);
  background-color: #fff;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 6px;
  border-radius: 4px;
  background-color: #fff;
}


::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 10px;
 
}


.linerGreadient-qw{
  position: absolute;
  z-index: 0;
  width: 100%;
  background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);
  top: 0;
  height: 180px;


}


.slb2{
  margin: 8px 14px;
  padding: 26px 0;
}

.slb{
  
  min-height: 280;
  overflow:auto;
  
}

.jjj{
  background-image: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%);



}



@media screen and (max-width: 600px) {
 .underGround2{
  width: 100% !important;
 }

 .menu-item-test{

  font-size: 24px;
  padding-block: 25px;
  
 }

 .menu-side-test{
  margin-top: 40px;
 }
  
}



.my-select-container .ant-select .ant-select-selector {
  border: 0 !important;
  border-bottom: 1px solid #333 !important;
}

.logo {
  height: 32px;

  margin: 10px 4px;

}

.cardContact{
  
  background-color: rgba(255, 255, 255, .1);

background-size: 10px 10px;


}
.gradient{
  padding: 20px 20px;
  
  background-image: linear-gradient(-60deg, #16a085 0%, #f4d03f 100%);
              border-radius: 15px;
        color: #f2f2f2;
        margin: 10px 0;
        display: block;

}

.logoBackground{
  background-color: #f5f5f5;

}

.LawyerHover:hover{

  transition: .5s all;
  border-radius: 30;

}

.LawyerSelected{
  border-radius: 30;
  

}

.logoColor{
  color: #333;

}


.scroll-lawyer::-webkit-scrollbar{
  width: 5px;
  display: initial;
  border-radius: 10px;
  
}

.scroll-lawyer::-webkit-scrollbar-thumb {

  border-radius: 10px;
}





.Setting-border{
  border-top: 1px solid #f5f5f5;

}

.signin{
  height: 100vh;
 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  overflow: hidden;
}

.unselecatbe_text{
  -webkit-user-select: none; /* Safari */        
-moz-user-select: none; /* Firefox */
-ms-user-select: none; /* IE10+/Edge */
user-select: none; /* Standard */
}



.trigger{
  margin: 0 10px;
}


.logoInMobile {
  height: 10px;
  margin: 8px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}




@media only screen and (max-width: 768px) {
  .hideOnMobile {
    display: none;
  }
}


@media only screen and (min-width: 768px) {
  .hideOnDesktop {
    display: none;
  }
} 


@media only screen and (max-width: 440px) {
  .slb {
    margin:  0;
    padding:  0,

  }
} 

.printTiShow{
  display: none;
}

@media only print {
  .printTiShow{
    display: block;
  }

  .matter_box_print{
    background-color: #f2f2f2 !important;
  }
}


.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 0px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;

  width: 100%;
  height: 30px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.container_base_team {
  max-width: 100%;
  width: 1200px;

}



.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.dsas{
  
}

nav {
  display: flex;
  margin: 1rem auto;
  align-items: center;
  justify-content: space-between;
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #f5f5f5;
}

.table-row-light2 {
  background-color: #1f1f1f;
}
.table-row-dark2 {
  background-color: #000000;
}


.flex-22{
  flex: 2 1 0%;
}

.logo55 {

  height: 60px;
  background-color: #006E78;
  display: flex;
  align-items: center;
  justify-content: center;
  
 
  
}

.text55 {
  display: inline-block;
 
  text-align: center;
  margin: auto;

  color: #fff;

}




.content {
  height: calc(100vh - 64px - 54px);
  overflow: auto;
}